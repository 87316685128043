<template>
  <section class="new_css">
    <el-row class="new_header">
      <el-col v-if="buttonList.length > 0" :span="24">
        <el-form
          :inline="true"
          ref="selectForm"
          :model="selectForm"
          class="select_form"
        >
          <el-form-item label="用户名:">
            <el-input
              v-model="selectForm.userName"
              placeholder="用户名"
            ></el-input>
          </el-form-item>
          <toolbar
            :buttonList="buttonList"
            @callFunction="callFunction"
            :buttonListmsg="buttonListmsg"
          ></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="calc-height">
      <!--列表-->
      <el-table
        :data="users"
        highlight-current-row
        height="100%"
     
        @current-change="selectCurrentRow"
        @row-dblclick="handleShow"
        style="width: 100%; margin-top: 15px"
      >
        <el-table-column prop="UserName" label="名称"> </el-table-column>
        <el-table-column label="调入公司" prop="IntoFactory"></el-table-column>
        <el-table-column label="调入部门" prop="IntoDpt"></el-table-column>
        <el-table-column label="调入岗位" prop="IntoPost"></el-table-column>
        <el-table-column label="调出公司" prop="OutFactory"></el-table-column>
        <el-table-column label="调出部门" prop="OutDpt"></el-table-column>
        <el-table-column label="调出岗位" prop="OutPost"></el-table-column>
        <el-table-column
          label="用工性质"
          prop="ContractNature"
        ></el-table-column>
        <el-table-column
          label="调动时间"
          prop="TransferDate"
          :formatter="formatStartTime"
        ></el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, jumper ,total"
      :page-count="total"
      :total="totaldata"
    >
    </el-pagination>
    <el-dialog
      :title="operation ? '调动' : '编辑'"
      :visible.sync="SakaryVisible"
      v-model="SakaryVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="SakaryForm"
        :model="SakaryForm"
        class="demo-form-inline"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="姓名:"
              prop="UserName"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input
                type="text"
                :disabled="true"
                v-model="SakaryForm.UserName"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="chooseUser"
                  v-if="operation"
                ></el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="调入公司:" prop="IntoFactory">
              <el-input type="text" v-model="SakaryForm.IntoFactory" disabled>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="调入部门:"
              prop="IntoDpt"
              :rules="[
                { required: true, message: '调入部门不能为空', trigger: 'blur' },
              ]"
            >
              <el-input type="text" v-model="SakaryForm.IntoDpt" disabled>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="调入岗位:"
              prop="IntoPost"
              :rules="[
                { required: true, message: '调入岗位不能为空', trigger: 'blur' },
              ]"
            >
              <el-input
                type="text"
                :disabled="true"
                v-model="SakaryForm.IntoPost"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="IntochooseUser"
                  v-if="operation"
                ></el-button>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="调出公司:"
              prop="OutFactory"
              :rules="[
                { required: true, message: '调出公司不能为空', trigger: 'blur' },
              ]"
            >
              <el-input type="text" v-model="SakaryForm.OutFactory" disabled>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="调出部门:"
              prop="OutDpt"
              :rules="[
                { required: true, message: '调出部门不能为空', trigger: 'blur' },
              ]"
            >
              <el-input
                type="text"
                :disabled="true"
                v-model="SakaryForm.OutDpt"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="调出岗位:"
              prop="OutPost"
              :rules="[
                { required: true, message: '调出岗位不能为空', trigger: 'blur' },
              ]"
            >
              <el-input
                type="text"
                :disabled="true"
                v-model="SakaryForm.OutPost"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="调动原因:" prop="Reason">
              <el-select
                v-model="SakaryForm.Reason"
                placeholder="调动原因"
                style="width: 100%"
              >
                <el-option label="晋升" value="晋升"></el-option>
                <el-option label="平调" value="平调"></el-option>
                <el-option label="降免职" value="降免职"></el-option>
                <el-option label="借调" value="借调"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="调动日期:"
              prop="TransferDate"
              :rules="[
                { required: true, message: '调动日期不能为空', trigger: 'blur' },
              ]"
            >
              <el-date-picker
                type="date"
                v-model="SakaryForm.TransferDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="用工性质:"
              prop="ContractNature">
              <el-select
                v-model="SakaryForm.ContractNature"
                placeholder="请选择"
                style="width: 100%"
                :disabled="true"
              >
                <!-- <el-option label="雇佣工" value="雇佣工"></el-option>
                <el-option label="承揽人" value="承揽人"></el-option>
                <el-option label="非劳动用工" value="非劳动用工"></el-option> -->
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
            <el-row>
          <el-col :span="24">
            <el-form-item
              label="调入考勤组"
              prop="AttendGroupName"
              :rules="[
                { required: true, message: '考勤组不能为空', trigger: 'blur' },
              ]"
            >
            <el-input
                type="text"
                :disabled="true"
                v-model="SakaryForm.AttendGroupName"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="selectAddress"
                ></el-button>
              </el-input>
           
            </el-form-item>
          </el-col>
        </el-row>
        <upload-files
          :files="SakaryForm.FileArry"
          :key="key"
          action="/cyl/ftp/ftp/upload"
          :limit="20"
          @fun="dealFiles"
          :IsDisabled="operation1"
          :IsDel="operation1"
        ></upload-files>
        <el-row v-if="operation1">
          <el-col :span="24" style="text-align: center">
          
              <el-button type="primary" v-on:click="onSubmit('SakaryForm')"
                >提交</el-button
              >
         
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <el-dialog
    title="选择人员"
      :visible.sync="addUserVisible"
      v-model="addUserVisible"
      width="75%"
      center
      append-to-body
    >
      <UserChoose
        :data="choosedusers"
        :key="key"
        :all="true"
        :single="false"
        @callback="usercallFunction"
      ></UserChoose>
    </el-dialog>
    <el-dialog
    title="选择职位"
      :visible.sync="IntoUserVisible"
      v-model="IntoUserVisible"
      width="75%"
      center
      append-to-body
    >
      <UserChoosejob
        :data="Intochoosedusers"
        :key="key"
        :all="true"
        :single="false"
        @callback="IntousercallFunction"
      ></UserChoosejob>
    </el-dialog>
     <el-dialog :visible.sync="addressVisible" v-model="addressVisible" width="75%" title="考勤地点" center append-to-body>
       <!-- <el-row>
            <el-form :inline="true" size="mini" style="padding-left:10px;">
                <el-form-item label="考勤组名称">
                    <el-input v-model="nameinput" placeholder="考勤组名称" size="mini"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="mini" plain @click="postsearch">查询</el-button>
                </el-form-item>
            </el-form> 
        </el-row> -->
      <el-table ref="multipleTable1" :data="AreaData" height="300" highlight-current-row max-height="500" style="width: 100%" @current-change="handleSelectionChange1">
        <el-table-column prop="Name" label="考勤组名称"> </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-button v-on:click="areaSelection">确认选择</el-button>
      </div>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import UserChoose from "../../components/UserChoose";
import UserChoosejob from "../../components/UserChoosejob";
import {
  GetTransferPageList,
  GetDepartmentOrg,
  AddTransferInfo,
  UpdateTransfer,
  GetUserPostDptOrg,
  GetTransferInfoDetail,
        GetAtPresentContractInfo,
} from "../../api/hr";
import {
    AttendGroupGetAttendGroupById
} from "../../api/attendance";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
import UploadFiles from "../../components/UploadFiles";
export default {
  components: { Toolbar, UserChoose, UserChoosejob, UploadFiles },
  data() {
    return {
      nameinput:"",
      loading: true,
      searchVal: "",
      selectForm: {
        userName: "",
      },
      key: 0,
      choosedusers: [],
      Outchoosedusers: [],
      Intochoosedusers: [],
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      InsuranVisible: false,
      SakaryVisible: false,
      IntoUserVisible: false,
      OutUserVisible: false,
      buttonListmsg: "",
      users: [],
      operation: false, // true:新增, false:编辑
      buttonList: [],
      addUserVisible: false,
      operation1: true,
        multipleSelection1:[],
      AreaData:[],
      addressVisible: false,
      SakaryForm: {
        UserId: "",
        UserName: "",
        IntoFactory: "",
        IntoDpt: "",
        IntoPost: "",
        IntoPostId: "",
        IntoDptId: "",
        OutFactory: "",
        OutDpt: "",
        OutPost: "",
        OutPostId: "",
        OutDptId: "",
        OutJobType: "",
        IntoJobType: "",
        Reason: "",
        TransferDate: "",
        ContractNature: "",
          AttendGroupName: "",
          AttendGroupId:"",
      },
      filters: {
        name: "",
      },
    };
  },
  methods: {
       handleSelectionChange1(val) {
          
      this.multipleSelection1 = val;
    },
      areaSelection() {
        
        this.SakaryForm.AttendGroupName = this.multipleSelection1.Name
          this.SakaryForm.AttendGroupId = this.multipleSelection1.Id
      // this.PermissionForm.AttendGroupAreaList = this.multipleSelection1;
      this.addressVisible = false;
     
    },
      selectAddress() {
      let that = this;
      this.$api.GetAllAttendGroup().then((res) => {
        console.log(res);
        this.AreaData = res.data.response;
        this.addressVisible = true;
      });
    },

    // postsearch () {
    //   let para = { Name: this.nameinput };
    //   console.log(para)
    //   this.$api.GetAllAttendGroup(para).then((res) => {
    //     console.log(res.data.response);
    //     this.AreaData = res.data.response;
    //     this.addressVisible = true;
    //     this.loading = false;
    //   });
    // },


    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    chooseUser() {
      this.addUserVisible = true;
    },
    IntochooseUser() {
      this.IntoUserVisible = true;
    },
    OutchooseUser() {
      this.OutUserVisible = true;
    },
    usercallFunction(newdata) {
      
      if (newdata.length > 1) {
        this.$message({
          message: "不允许选择多人",
          type: "error",
        });
        return;
      }

      this.SakaryForm.UserId = newdata[0].Id;
      this.SakaryForm.UserName = newdata[0].UserNameClaim;
      GetAtPresentContractInfo({ userId: newdata[0].Id }).then((res) => {
        this.SakaryForm.ContractNature = res.data.response.ContractNature;
      });
        
        GetUserPostDptOrg({ isMainJob: 1, userId: newdata[0].Id }).then((res) => {
            if (res.data.success) {
                this.SakaryForm.OutFactory = res.data.response.OrganizationName;
                this.SakaryForm.OutDpt = res.data.response.DepartmentName;
                this.SakaryForm.OutPost = res.data.response.PostName;
                this.SakaryForm.OutPostId = res.data.response.PostId;
                this.SakaryForm.OutDptId = res.data.response.DepartmentId;
                this.SakaryForm.OutJobType = res.data.response.PostWorkTypeId;
        } else {
          this.$message({
            message: "获取部门信息出错",
            type: "error",
            duration: 5000,
          });
        }
      });
      this.addUserVisible = false;
    },

    IntousercallFunction(newdata) {
      this.SakaryForm.IntoPost = newdata.Name;
      this.SakaryForm.IntoDptId = newdata.DepartmentId;
      this.SakaryForm.IntoPostId = newdata.Id;
      this.SakaryForm.IntoJobType = newdata.WorkTypeId;
        GetDepartmentOrg({ deptId: newdata.DepartmentId }).then((res) => {
        if (res.data.code == 200) {
            if (res.data.data.deptName != null) {
                this.SakaryForm.IntoFactory = res.data.data.deptName;
          } else {
            this.SakaryForm.IntoFactory = "";
          }

            this.SakaryForm.IntoDpt = res.data.data.queryDeptName;
        } else {
          this.$message({
            message: "获取部门信息出错",
            type: "error",
            duration: 5000,
          });
        }
      });
      this.IntoUserVisible = false;
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    //获取列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        userName: this.selectForm.userName,
      };
      
      GetTransferPageList(para).then((res) => {
        
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
        }
        let asdasd = "";
        AttendGroupGetAttendGroupById({ attendGroupId: row.AttendGroupId }).then((res) => {
            this.SakaryForm.AttendGroupName = res.data.response.Name;
            asdasd = res.data.response.Name;
            GetTransferInfoDetail({ id: row.Id }).then((res) => {
                this.SakaryForm = res.data.response;
                this.SakaryForm.AttendGroupName = asdasd;
            });
        });
        
        
      GetAtPresentContractInfo({ userId: row.UserId }).then((res) => {
        this.SakaryForm.ContractNature = res.data.response.ContractNature;
        this.key += 1;
        this.SakaryVisible = true;
        
      });

      this.operation = false;
      this.operation1 = true;
    },
    handleShow() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }

      GetTransferInfoDetail({ id: row.Id }).then((res) => {
        console.log(res);
        this.SakaryForm = res.data.response;
      });

      GetAtPresentContractInfo({ userId: row.UserId }).then((res) => {
        console.log(res);
        this.SakaryForm.ContractNature = res.data.response.ContractNature;
        this.key += 1;
        this.SakaryVisible = true;
        
      });

      this.operation = false;
      this.operation1 = false;
    },
    handleAdd() {
      (this.SakaryForm = {
        UserId: "",
        UserName: "",
        IntoFactory: "",
        IntoDpt: "",
        IntoPost: "",
        IntoPostId: "",
        IntoDptId: "",
        OutFactory: "",
        OutDpt: "",
        OutPost: "",
        OutPostId: "",
        OutDptId: "",
        OutJobType: "",
        IntoJobType: "",
        Reason: "",
        TransferDate: "",
        ContractNature: "",
        FileArry: [],
          AttendGroupName: "",
          AttendGroupId: "",
      }),
        (this.key += 1);
      (this.operation = true), (this.SakaryVisible = true);
      this.operation1 = true;
    },

      onSubmit(formName) {
          console.log("train:", this.SakaryForm);
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let para = Object.assign({}, this.SakaryForm);
            
            if (this.operation) {
              //新增
              AddTransferInfo(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: "添加成功",
                    type: "success",
                    duration: 5000,
                  });
                  this.SakaryVisible = false;
                  this.getData();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                    duration: 5000,
                  });
                }
              });
            } else {
              //修改
              UpdateTransfer(para).then((res) => {
                
                if (res.data.success) {
                  this.$message({
                    message: res.data.msg,
                    type: "success",
                    duration: 5000,
                  });
                  this.SakaryVisible = false;
                  this.getData(false);
                } else {
                  this.$message({
                    message: "修改失败",
                    type: "error",
                    duration: 5000,
                  });
                }
              });
            }
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
            return false;
          }
        });
      });
    },
    dealFiles(data) {
      
      this.SakaryForm.FileArry = data.backData;
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>

